/*
公共的请求方法
*/
import base from './base'
import axios from '@/utils/request'
const api = {
    /**
     * 登录
     */
    Login(param){
        let customConfig = {
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        return axios.post(base.login,param,customConfig)
            
    },
    Search(param){
        let customConfig = {
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }
        return axios.post(base.SearchUrl,param,customConfig) 
    },
    // 页面刷新
    Refresh(param){
            return axios.post(base.Refresh,param) 
        },
    // Detail(param,token){
    //     let customConfig = {
    //         headers:{
    //             'Content-Type': 'application/json',
    //             'Merchanttoken':token
    //         }
    //     }
    //     return axios.post(base.detailUrl,param,customConfig) 
    // },
    // getCard(token){
    //     let customConfig = {
    //         headers:{
    //             'Content-Type': 'application/json',
    //             'Merchanttoken':token
    //         }
    //     }
    //     return axios.post(base.cardUrl,{},customConfig) 
    // },
    Send(param){
        let customConfig = {
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        return axios.post(base.sendUrl,param,customConfig) 
    },
    /**房态查询 */
    // roomLogin(param){
    //     let customConfig = {
    //         headers:{
    //             'Content-Type': 'application/x-www-form-urlencoded',
    //         }
    //     }
    //     return axios.post(base.roomLogin,param,customConfig)    
    // },
    // 刷新房间首页
    // refreshIndex(Cookie){
    //     let customConfig = {
    //         headers:{
    //             'Cookie': Cookie,
    //         }
    //     }
    //     return axios.get(base.roomIndex,customConfig)    
    // },
    // 房态
    // getRoomState(param,Cookie){
    //     let customConfig = {
    //         headers:{
    //             'Cookie': Cookie,
    //             'Content-Type': 'application/x-www-form-urlencoded',
    //         }
    //     }
    //     return axios.post(base.roomState,param,customConfig)    
    // },
    // 房情
    // getRoomCondition(param,Cookie){
    //     let customConfig = {
    //         headers:{
    //             'Cookie': Cookie,
    //             'Content-Type': 'application/x-www-form-urlencoded',
    //         }
    //     }
    //     return axios.post(base.roomCondition,param,customConfig)    
    // },
    //工程房态
    // getGcft(){
    //     return axios.get(base.gcFangtai)
    // },
    // 添加事件
    // addTodo(param){
    //     let customConfig = {
    //         headers:{
    //             'Content-Type': 'application/json',
    //         }
    //     }
    //     return axios.post(base.addTodo,param,customConfig)
    // },
    // 删除事件
    // delTodo(param){
    //     let customConfig = {
    //         headers:{
    //             'Content-Type': 'application/json',
    //         }
    //     }
    //     return axios.post(base.delTodo,param,customConfig)
    // },
    
    // 删除事件
    // doneTodo(param){
    //     let customConfig = {
    //         headers:{
    //             'Content-Type': 'application/json',
    //         }
    //     }
    //     return axios.post(base.doneTodo,param,customConfig)
    // },
    // 查询事件
    // getTodo(param){
    //     let customConfig = {
    //         headers:{
    //             'Content-Type': 'application/x-www-form-urlencoded',
    //         }
    //     }
    //     return axios.post(base.getTodo,param,customConfig)
    // },
    // 查询所有事件
    // getAllTodo(){
    //     return axios.get(base.getAllTodo)
    // }

    //添加房间
    // addRoom(param){
    //     let customConfig = {
    //         headers:{
    //             'Content-Type': 'application/x-www-form-urlencoded',
    //         }
    //     }
    //     return axios.post(base.addRoom,param,customConfig)
    // }
}

export default api