<template>
  <div class="page">
    <div class="card">
      <div class="text">
        <h1>商家登录</h1>
      </div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="uname"
          name="用户名"
          label="用户名"
          placeholder="用户名"
          :rules="[{ required: true, message: '请填写用户名' }]"
        />
        <van-field
          v-model="pwd"
          name="密码"
          label="密码"
          type="password"
          placeholder="密 码"
          :rules="[{ required: true, message: '请填写密码' }]">
        </van-field>
        <div style="margin: 16px">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      uname: "",
      pwd: "",
      // iconName:'closed-eye',
      // pwdType:'password'
    };
  },
  // computed:{
  //   uname(){
  //     return this.$cookies.get('uname')
  //   },
  //   pwd(){
  //     return this.$cookies.get('pwd')
  //   }
  // },
  methods: {
    // changeType(){
    //   if(this.pwdType="password"){
    //     this.iconName ='eye-o'
    //     // this.$refs.pwdType.type="text"
    //     this.$nextTick(()=>{
    //       this.pwdType="text"
    //     })
    //   }
    //   if(this.pwdType="text"){
    //     this.iconName ='closed-eye'
    //     // this.$refs.pwdType.type="password"
    //     this.$nextTick(()=>{
    //       this.pwdType="password"
    //     })
    //   }

    // },
    async onSubmit() {
      let res = await this.$api.Login({
        account: this.uname,
        passwd: this.pwd,
      });
      // console.log(res.data)
      if (res.data.code!== 0) {
        Toast(res.data.msg);
        return;
      }
      if (res.data.code == 0) {
        // console.log(res.data)
        this.$store.state.token = res.data.data.token;
        this.$store.state.shop = res.data.data.shop;
        this.$store.state.shopCouponList = res.data.data.shopCouponList;
        this.$store.state.shopUser = res.data.data.shopUser;
        this.$cookies.set("token", res.data.data.token);
        this.$cookies.set("userName", res.data.data.shop.linkname);
        this.$cookies.set('balance',res.data.data.shop.balance)
        // this.$cookies.set('pwd',this.pwd)
        localStorage.setItem("uname", this.uname);
        localStorage.setItem("pwd", this.pwd);
        Toast('登陆成功')
        this.$emit("goSearch");
      }
    },
  },
  created() {
    this.uname = localStorage.getItem("uname");
    this.pwd = localStorage.getItem("pwd");
  },
  // updated(){
  //     if(this.pwdType="password"){
  //       this.iconName ='eye-o'
  //         this.pwdType="text"

  //     }
  //     if(this.pwdType="text"){
  //       this.iconName ='closed-eye'
  //         this.pwdType="password"
  //       }
  // }
};
</script>

<style lang="less" scoped>
.page {
  // background: url("../../assets/carlogin1.gif");
  // width: 100vw;
  // height: 100vh;
  // position: fixed;
  // background-size: 100% 100%;
  // text-align: center;
  .card {
    width: 80vw;
    height: 40vh;
    margin: 0 auto;
    // border:1px solid #000;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .text {
      text-align: center;
      color: #56e936;
    }
  }
}
.van-form {
  // width: 50vw;
  margin: 2vh auto;
  border-radius: 25px;
  .van-cell {
    // font-size: 32px;
    background: rgba(255, 255, 255, 0);
  }
  .van-button--normal {
    // width: 10vw;
    // margin: 0 auto;
    font-weight: 700;
  }
}
/deep/ .van-field__label {
  color: #fff;
}
/deep/ .van-field__control{
  color: #fff;
}
</style>