/*
    公共的接口配置资源
*/

const base = {
    host:'https://g.qcyuns.com',//基本地址
    // host1:'http://139.186.176.85:8104',//
    login:'/api/park-shop/login',//登录
    SearchUrl:'/api/park-shop/getCarIn',//查询
    Refresh:'/api/park-pay/park/getBusiness?shopId=1330',//刷新
    sendUrl:'/api/park-shop/pc/confirmOrder',//发券

}

// 单个导出
// export const host='https://parking.szccbt.com'

export default base